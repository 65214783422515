$(document).ready(function() {
    $("#toggle-visibility").on("click", function() {
        var x = document.getElementById("user_password");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";    
        }
    });
});
  
console.log("BS");